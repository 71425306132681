import store from '@/store'
import router from '@/router'

export const setActiveSection = async (to, _from, next) => {
  switch (to.name) {
    case "index":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Home")
      break
    case "consulting":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Services")
      break
    case "implementierung":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Services")
      break
    case "optimierung":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Services")
      break
    case "projektmanagement":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Services")
      break
    case "outsourcing":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Services")
      break
    case "tisa":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Services")
      break
    case "karriere":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Career")
      break
    case "kontakt":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Contact")
      break
    case "support":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Contact")
      break
    case "impressum":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Contact")
      break
    case "datapolicy":
      store.dispatch("UPDATE_ACTIVE_SECTION", "Contact")
      break
    default:
      store.dispatch("UPDATE_ACTIVE_SECTION", "Home")
      break
  }

  return void next();
}
