import Vue from 'vue'

// ==================================================================
// Import Vue Meta.
import Meta from 'vue-meta'
Vue.use(Meta)

// ============================================================================
// Import vuelidate.
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// ==================================================================
// Import bootstrap-vue. We import only the individual plugins
// that we are actually using to reduce the bundle size.
import {
  LayoutPlugin,
  LinkPlugin,
  NavPlugin,
  NavbarPlugin
} from 'bootstrap-vue'
for (let plugin of [
  LayoutPlugin,
  LinkPlugin,
  NavPlugin,
  NavbarPlugin
]) {
  Vue.use(plugin)
}

// ==================================================================
// Import bootstrap-vue icons.
import {
  BIcon,
  BIconCaretDownSquare,
  BIconCaretUpSquare
} from 'bootstrap-vue'
for (let component of [
  BIcon,
  BIconCaretDownSquare,
  BIconCaretUpSquare
]) {
  const { options: { name } } = component
  Vue.component(name, component)
}
