<template>
<span :class="$style.layout">
  <Header />
  <!--- Spacer --->
  <!-- <b-container style="height:25px"></b-container> -->
  <!-- If we have matched any route, display it -->
  <router-view v-if="$route.matched.length"
    id="content"
    :class="$style.content"
  />
  <Footer />
</span>
</template>

<style lang="scss" module>
  @import "src/variables.scss";
  // @import url('/fonts/Inter-Regular.ttf');

  .layout {
    font-family: 'Inter', 'sans-serif';
    white-space: pre-wrap;
  }

  .content {
    background-color: #fff;
    // padding: 15px 34px 60px 34px;
    width: 100%;

    h1, h2, h3, h4 {
      color: black;
      font-weight: 1000 !important;
      //text-shadow: 1px 1px 1px #e0e0e0;
    }
  }
</style>

<script>
  import Vue from 'vue'

  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";

  export const Events = new Vue()

  export default {
    components: {
      Header,
      Footer
    },
    mounted () {
      // let lang = window.sessionStorage.getItem("lang");
      // if (!lang) {
      //   window.sessionStorage.setItem("lang", "DE");
      // }
    },
    metaInfo () {
      const metaInfo = {
        titleTemplate: '%s - redhouse GmbH'
      }

      return metaInfo
    }
  }
</script>
