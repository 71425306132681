import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import routes from './routes.js'
import { setActiveSection } from './middleware.js'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_from, _to, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(setActiveSection)

export default router
