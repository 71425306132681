// We only use a single chunk for all views here, as
// I suspect it would create more overhead than gains
// firing 10+ requests at the same time.
const Index             = () => import(/* webpackChunkName: "views" */ '@/views/Index.vue')
const Services          = () => import(/* webpackChunkName: "views" */ '@/views/Services.vue')
const DataPolicy        = () => import(/* webpackChunkName: "views" */ '@/views/DataPolicy.vue')
const Impressum         = () => import(/* webpackChunkName: "views" */ '@/views/Impressum.vue')
const Karriere          = () => import(/* webpackChunkName: "views" */ '@/views/Karriere.vue')
const Kontakt           = () => import(/* webpackChunkName: "views" */ '@/views/Kontakt.vue')
const Support           = () => import(/* webpackChunkName: "views" */ '@/views/Support.vue')
const AGB               = () => import(/* webpackChunkName: "views" */ '@/views/AGB.vue')


export default [
  {
    name: 'index',
    path: '/index',
    component: Index
  },
  {
    name: 'leistungen',
    path: '/leistungen',
    component: Services
  },
  {
    name: 'datapolicy',
    path: '/datapolicy',
    component: DataPolicy
  },
  {
    name: 'impressum',
    path: '/impressum',
    component: Impressum
  },
  {
    name: 'karriere',
    path: '/karriere',
    component: Karriere
  },
  {
    name: 'kontakt',
    path: '/kontakt',
    component: Kontakt
  },
  {
    name: 'support',
    path: '/support',
    component: Support
  },
  {
    name: 'agb',
    path: '/agb',
    component: AGB
  },
  {
    name: 'root',
    path: '/',
    redirect: 'index'
  },
  {
    name: 'error',
    path: '*',
    redirect: '/'
    //component: Error
  }
]