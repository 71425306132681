import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import * as mutations from './mutations.js'
import * as actions from './actions.js'
import * as getters from './getters.js'

const state = {
    activeSection: {
        "section": "Home"
    },
    lang: "DE",
    header: null
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})