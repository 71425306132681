// ==================================================================
// Inject the projects version information into the
// global window object.

// ==================================================================
// Import Vue.
import Vue from 'vue'
Vue.config.devtools
  = Vue.config.productionTip

export const EventBus = new Vue();

// ==================================================================
import App    from '@/App.vue'
import store  from '@/store'
import router from '@/router'

// ==================================================================
// Bootstrap / BootstrapVue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// ==================================================================
// Import other dependencies.
import '@/deps.js'
import '@/deps.scss'

// ==================================================================
// Register global mixins.
import './assets/scss/style.scss'
// ==================================================================
// Register plugins.

// ==================================================================
// Register global components.

// ==================================================================
// Fire up the Vue runtime.
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#v-app')
