<template>
<footer id="redhouseFooter" :class="$style.footer">
  <b-container>
    <!-- <b-row :class="$style.brand" class="my-4">
      <b-col sm="12" md="4">
        <b-row class="mt-2" align-h="center">redhouse</b-row>
        <b-row align-h="center">we do<span :class="$style.red" class="ml-2">IT.</span></b-row>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row> -->
    <b-row class="mt-4">
      <b-col sm="6" md="3" class="my-4">
        <b-row align-h="center">redhouse</b-row>
        <b-row align-h="center">we do<span :class="$style.red" class="ml-2">IT.</span></b-row>
      </b-col>
      <b-col sm="6" md="3" class="my-4">
        <b-row align-h="center"><router-link :to="{ name: 'impressum' }">{{ findText(["impressum", "imprint"]) }}</router-link></b-row>
        <b-row align-h="center"><router-link :to="{ name: 'datapolicy' }">{{ findText(["datenschutz", "data policy"]) }}</router-link></b-row>
        <b-row align-h="center"><router-link :to="{ name: 'agb' }">{{ findText(["agb", "tos"]) }}</router-link></b-row>
      </b-col>
      <b-col sm="6" md="3" class="my-4">
        <b-row class="mb-1" align-h="center"><router-link :to="{ name: 'kontakt' }">{{ findText(["kontakt", "contact"]) }}</router-link></b-row>
        <b-row :class="$style.contact" align-h="center"><a href="tel:0049221671170">+49 (221) 67117-0</a></b-row>
        <b-row :class="$style.contact" align-h="center"><a href="mailto:contact@redhouse.de">contact@redhouse.de</a></b-row>
      </b-col>
      <b-col sm="6" md="3" class="my-4">
        <b-row class="mb-1" align-h="center">redhouse GmbH</b-row>
        <b-row :class="$style.contact" align-h="center">Carlswerkstraße 11a</b-row>
        <b-row :class="$style.contact" align-h="center">51063 Köln</b-row>
      </b-col>
    </b-row>
  </b-container>
</footer>
</template>

<style lang="scss" module>
  @import 'src/_variables.scss';

  .footer {
    // position: absolute;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    font-size: 11pt;
    font-weight: bold;
    color: black !important;
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
    position: relative;
    z-index: 10 !important;

    a:link, a:visited {
      color: black !important;
      font-size: 11pt;
    }
    a:hover {
      color: #E41f2b !important;
      font-size: 11pt;
      text-decoration: none !important;
    }
  }

  .brand {
    font-size: 16pt;
  }

  .contact {
    font-weight: normal;
  }

  .red {
    color: #E41f2b;
  }
</style>

<script>
  import { EventBus } from '@/main.js';

  export default {
    data () {
      return {
        footerStyle: "Home"
      }
    },
    computed: {
      currentLang () {
        return this.$store.getters.lang;
      }
    },
    methods: {
      findText (translations) {
        switch (this.currentLang) {
          case "DE":
            return translations[0];
          case "EN":
            return translations[1];
          default:
            return translations[0];
        }
      }
    },
    mounted () {
      EventBus.$on('changeActiveSection', (section) => {
        this.footerStyle = section.section;
        let el = document.getElementById("redhouseFooter");
        if (section.section == 'imprint' && screen.width > 700 && document.getElementById("content").offsetHeight < (window.innerHeight - el.offsetHeight - document.getElementById("redhouseHeader").offsetHeight)) {
          el.style.position = "fixed";
          el.style.bottom = "0";
        }
        else {
          el.style.position = "relative";
          el.style.bottom = null;
        }
      });
    }
  };
</script>
